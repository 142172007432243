import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Tabs from "./controls/tabs";
import { toast } from "react-toastify";
import common_axios from "./utils/common_axios";

const PAGE_TABS = [
  {
    id: "/create-challenge",
    name: "Create Challenge",
  },
  {
    id: "/create-closet",
    name: "Create Closet",
  },
  {
    id: "/create-placeholder",
    name: "Create Placeholder",
  },
];

export default function Layout() {
  const [selectedTab, setSelectedTab] = useState("create-challenge");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname) {
      setSelectedTab(location.pathname);
    }
  }, [location]);

  const onClickCookie = async () => {
    try {
      const userInput = window.prompt("Please paste the json data:");
      if (userInput !== null) {
        const parsedData = JSON.parse(userInput);
        if (!parsedData.length) {
          toast.error("Cookie isn't valid");
          return;
        }
        if (!parsedData.find((item) => item.name === "glance-studio-session")) {
          toast.error("Cookie isn't valid");
          return;
        }
        await common_axios.post("/addCookie", parsedData);
        toast.success("Cookie added");
      }
    } catch (e) {
      console.log(e);
      toast.error("Failed to add cookie");
    }
  };

  return (
    <div>
      <div className="h-full flex flex-1 border-b-2 border-gray-700 justify-between">
        <Tabs
          tabs={PAGE_TABS}
          selectedTab={selectedTab}
          onTabClick={(tab) => navigate(tab)}
          tabType="full-width"
        />
        <div
          onClick={onClickCookie}
          role="button"
          className="material-icons-outlined text-primary-500 text-lg rounded-full cursor-pointer flex justify-center items-center mr-3"
        >
          cookie
        </div>
      </div>
      <Outlet />
    </div>
  );
}
